<template>
  <div v-if="year" class="coupe-de-france-du-potager-evaluation max-w-2xl mt-1">
    <div class="w-100 d-flex align-items-center justify-content-center">
      <img
          :alt="$t('logoPath')"
          class="height-100"
          :src="logoPath">
    </div>
    <b-alert show class="text-center max-w-md p-1" :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary'">
      {{ $t('previousYear.nonEditableSurvey', { year }) }}
    </b-alert>
    <div v-if="askRetrievePrevious" class="w-100 d-flex align-items-center justify-content-between">
      <b-card no-body>
        <b-card-body v-if="retrieveStatus === '' ">
          <p class="text-justify">
            {{ $t('previousYear.noResponseYet', { year }) }}
          </p>
          <b-button @click="askRetrieve">
            <feather-icon icon="SearchIcon" class="text-primary mr-1"/>
            <span class="text-primary">{{ $t('previousYear.retrieveButtonText') }}</span>
          </b-button>
        </b-card-body>
        <b-card-body v-if="retrieveStatus === 'alreadyAsked' ">
          {{ $t('previousYear.retrieveRequestInProgress') }}
        </b-card-body>
        <b-card-body v-if="retrieveStatus === 'noPrevious' ">
          {{ $t('previousYear.noPreviousResults') }}
        </b-card-body>
        <b-card-body v-if="retrieveStatus === 'noQuizThisYear' ">
          {{ $t('previousYear.noQuizThisYear', { year }) }}
        </b-card-body>
        <b-card-body v-if="retrieveStatus === 'done' ">
          {{ $t('previousYear.requestAccepted') }}
        </b-card-body>
      </b-card>
    </div>
    <CFPCategories v-if="quiz" :quiz="quiz" :providedQuizAnswerer="quizAnswerer" />
  </div>
</template>

<script>
import {BAlert, BButton, BCard, BCardBody} from "bootstrap-vue";
import CFPCategories from "@/components/cfp/CFPCategories.vue";
import logoEn from '@/assets/images/cfp/logo_cfp_en.png';
import logoFr from '@/assets/images/cfp/logo_coupe.png';
import logoEs from '@/assets/images/cfp/logo_cfp_es.png';
export default {
  name: "CFPPastYear.vue",
  components: {
    BAlert,
    BButton,
    BCard,
    BCardBody,
    CFPCategories
  },
  data() {
    return {
      year: null,
      quizAnswerer: null,
      askRetrievePrevious: false,
      retrieveStatus: "",
      quiz: null,
      logos: { en: logoEn, fr: logoFr, es: logoEs }
    }
  },
  mounted() {
    const splitRoute = this.$route.fullPath.split("/")
    if (splitRoute.length > 1) {
      this.year = splitRoute.slice(-1)[0].split('#')[0]
      this.$store.dispatch("cfp/getQuizAnswerer", {year: this.year}).then((response) => {
        if (!response.data.length) {
          this.askRetrievePrevious = true
        }
        else {
          this.quizAnswerer = response.data[0]
          this.$store.dispatch("cfp/retrieveQuiz", {id: this.quizAnswerer.quiz})
              .then((response) => {
                this.quiz = response.data
              })
              .catch(() => {
                this.$notify(
                    {
                      title: this.$t('Error'),
                      text: this.$t('notify.quizFetchError'),
                      time: 3000,
                      color: "danger"
                    }
                )
              })
        }
      })
      .catch(() => {
        this.$notify(
            {
              title: this.$t('Error'),
              text: this.$t('notify.pastYearFetchError'),
              time: 3000,
              color: "danger"
            }
        )
      })
   }
  },
  methods: {
    askRetrieve() {
      this.$store.dispatch("cfp/askQuizRetrieval")
          .then(() => {
            this.retrieveStatus = "done"
          })
          .catch((err) => {
            this.$notify(
                {
                  title: this.$t('Error'),
                  text: this.$t('notify.quizFetchError'),
                  time: 3000,
                  color: "danger"
                }
            )
            if (err.response && err.response.status){
              if (err.response.data && err.response.status==403) {
                if (err.response.data.detail === 'request already done') {
                  this.retrieveStatus = "alreadyAsked"
                }
                if (err.response.data.detail === 'No quiz for this year') {
                this.retrieveStatus = "noQuizThisYear"
                }
              }
              if (err.response.status == 404) {
                this.retrieveStatus = "noPrevious"
              }
            }
          })
    }
  },
  computed: {
    logoPath(){
      const currentLang = this.$i18n.locale; // Assuming you have access to $i18n object
      return this.logos[currentLang] || this.logos.en;
    }
  }
}

</script>

<style scoped>

</style>